import { useEffect, useState } from "react";
import fetchApi from "../services/fetch-data.service";

const useFetch = (query, interval = undefined) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const res = await fetchApi(query);
                if (isMounted && res.status === 200) {
                    setData(res.data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };
        if (interval != undefined) {
            setInterval(() => {
                fetchData();
            }, interval)
        } else {
            fetchData();
        }


        return () => {
            setData([]);
            setIsLoading(true);
            isMounted = false;
        };
    }, [query]);

    return {
        data,
        isLoading,
    };
};

export default useFetch;
