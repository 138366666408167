import React from 'react'

export default function ThankYouMessage() {
  return (
    <>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xs-12 col-md-4">
            <div class="paymentsuccess thankyou">
              <h4>Thank you!</h4>
              <span>You will get reward points for your spends</span>
              {/* <a href="#">Check your payment status</a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
