import React, { useEffect, useRef, useState } from "react";
import sendbillimg from "../../img/sendbill.png";
import mainlogo from "../../img/logo-dyp.png";
import dummyqr from "../../img/dummyqr.jpg";
import * as htmlToImage from "html-to-image";
import QRCode from "react-qr-code";
import axios from "axios";
import { API_URL, create } from "../../services/upload-files.service";
import useFetch from "../../hooks/useFetch";
import { formatDate } from "./Functions";
import fetchApi from "../../services/fetch-data.service";

import { Button, Modal } from "react-bootstrap";

export default function AllGuest() {
  const [urlData, setUrlData] = useState({});
  const [url, setUrl] = useState(
    `https://impact.diverseyp.com?store_id=${localStorage.getItem("storeId")}`
  );
  const [qrIsVisible, setQrIsVisible] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isMounted, setIsMounted] = useState(true);
  const [data, setData] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showaddSpend, setShowaddSpend] = useState(false);
  const handleCloseaddSpend = () => setShowaddSpend(false);
  const handleShowaddSpend = () => setShowaddSpend(true);
  useEffect(() => {
    const fetchData = async (isMounted) => {
      try {
        const { data } = await fetchApi(
          `stores/spends?store_id=${localStorage.getItem("storeId")}`
        );
        if (isMounted && data.success) {
          if (data.data && Object.keys(data.data).length > 0) {
            setData(data.data);
            setIsMounted(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(isMounted);
  }, [isMounted]);

  const handleQrCodeGenerator = () => {
    if (!url) {
      return;
    }
    setQrIsVisible(true);
  };

  const qrCodeRef = useRef(null);
  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUrlData((prevData) => ({
      ...prevData,
      [name]: value,
      store_id: localStorage.getItem("storeId"),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(urlData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        if (Object.keys(urlData).length > 0) {
          const res = await create(urlData, "stores/add-spends");
          if (res.success) {
            setUrlData({}); // Clear form data here
            setIsMounted(true);
            handleCloseaddSpend(true);
            setAlertMessage("Spending added successfully!");
            setValidationErrors({});
            setTimeout(() => {
              setAlertMessage("");
            }, 3000); // Close modal after 3 seconds
          } else {
            setValidationErrors({ err: res.msg });
          }
        }
      } catch (error) {
        setValidationErrors({
          err: "An error occurred. Please try again later.",
        });
      }
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.amount || formData.amount.trim() === "") {
      errors.amount = "Please enter amount";
    } else if (!validateNumber(formData.amount)) {
      errors.amount = "Please enter a valid  amount";
    }

    return errors;
  };

  const validateNumber = (amount) => {
    const re = /^(?!0\d)\d*(\.\d+)?$/;
    return re.test(amount);
  };

  return (
    <>
      <div className="container-fluid greybg">
        <div className="mainheading">
          <h4>All Guest</h4>

          <div className="addbuttons">
            <button
              onClick={handleShowaddSpend}
              type="button"
              className="btn btn-primary"
            >
              Add Spends
            </button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addqrcode"
              className="btn btn-warning"
              onClick={handleQrCodeGenerator}
            >
              Generate QR Code
            </button>
          </div>
        </div>
        <div className="alertmessage">
          {alertMessage && (
            <div className="alert alert-success" role="alert">
              {alertMessage}
            </div>
          )}
        </div>
        <div className="allguest">
          {data && data.length > 0 ? (
            data.map((val) => {
              return (
                <div className="guestblocks addspendscard" key={val.id}>
                  <span>{formatDate(val.created_at)}</span>
                  <div className="nameamount">
                    <h4>Guest {val.id} </h4>
                    <h5>
                      Amount : <strong>$ {val.amount}</strong>
                    </h5>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>

      <Modal show={showaddSpend} onHide={handleCloseaddSpend}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sendbillmodal">
            <img src={sendbillimg} alt="Send Bill" />
            <form className="billingdetails" onSubmit={handleSubmit}>
              <h4>Add Spends</h4>
              <input
                className="form-control"
                type="text"
                value={urlData.amount ? urlData.amount : ""}
                name="amount"
                placeholder="Enter Amount"
                onChange={handleChange}
              />
              {validationErrors.amount && (
                <div className="text-danger red-color">
                  {validationErrors.amount}
                </div>
              )}

              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn btn-primary arrowbluebtn"
              >
                Submit <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <div
        className="modal fade"
        id="addqrcode"
        tabIndex="-1"
        aria-labelledby="addqrcode"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body addqrcodemodalbody">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="addqrcodemodal">
                <div className="qrcode__container--parent" ref={qrCodeRef}>
                  <div className="qrcode__input">
                    <div className="nbadyplogo">
                      <img src={mainlogo} alt="DYP Local Support" />
                      <h4>DYP Local Support</h4>
                      <h5>{localStorage.getItem("storename")}</h5>
                      <div className="genratedqucode">
                        {qrIsVisible && (
                          <div className="qrcode__download">
                            <div className="qrcode__image">
                              <QRCode value={url} size={400} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary" onClick={downloadQRCode}>
                  Download QR Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
