import React from "react";

export default function AddBankDetails() {
  return (
    <>
      <div class="container-fluid greybg">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xs-12 col-md-4">
              <div class="ownerlogin">
                <form>
                  <h4>add bank details</h4>
                  <div class="mb-3">
                    <label for="name" class="form-label">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="name"
                      name="bankname"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="restaurantname" class="form-label">
                      Account Number
                    </label>
                    <input type="text" class="form-control" name="accountno" />
                  </div>
                  <div class="mb-3">
                    <label for="username" class="form-label">
                      Swift Code
                    </label>
                    <input type="text" name="swiftcode" class="form-control" />
                  </div>
                  <div class="mb-3">
                    <label for="username" class="form-label">
                      Branch Location
                    </label>
                    <input
                      type="text"
                      name="branchlocation"
                      class="form-control"
                    />
                  </div>
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
