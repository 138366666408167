import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("storeId");
  };

  return (
    <>
      <nav class="navbar bg-primary navbar-expand-lg " data-bs-theme="dark">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/store/allguest">
            Home
          </Link>

          <div class="collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul class="navbar-nav">
              <li class="nav-item dropdown dropstart">
                <a
                  class="dropdown-toggle bardrowpdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-bars fa-2x"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-custom">
                  {/* <li>
                    <Link class="dropdown-item" to="/store/addbankdetails">
                      Bank Details
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/store/paymenthistory">
                      Payment History
                    </Link>
                  </li> */}
                  <li>
                    <a
                      class="dropdown-item redbg"
                      href="/store"
                      onClick={handleLogout}
                    >
                      {" "}
                      <i class="fa-solid fa-arrow-right-from-bracket"></i>{" "}
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
