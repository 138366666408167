import React from "react";
import "./App.css";
import Login from "./components/restaurantownerflow/Login";
import { Route, Routes } from "react-router-dom";
import Register from "./components/restaurantownerflow/Register";
import AllGuest from "./components/restaurantownerflow/AllGuest";
import PaymentHistory from "./components/restaurantownerflow/PaymentHistory";
import DefaultContainer from "./components/restaurantownerflow/DefaultContainer";
import AuthRoute from "./AuthRoute";
import AddBankDetails from "./components/restaurantownerflow/AddBankDetails";
function App() {
  return (
    <>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route element={<DefaultContainer />}>
            <Route path="/allguest" element={<AllGuest />} />
            <Route path="/paymenthistory" element={<PaymentHistory />} />
            <Route path="/addbankdetails" element={<AddBankDetails />} />
          </Route>
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </>
  );
}

export default App;
