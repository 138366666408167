import React, { useState } from "react";
import mainlogo from "../../img/logo-dyp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { create } from "../../services/upload-files.service";

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    contact: "",
    store_name: "",
    password: "",
    reenterpassword: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      const { reenterpassword, ...formDataWithoutReenterPassword } = formData;

      try {
        const res = await create(formDataWithoutReenterPassword, "register");
        if (res && res.success) {
          setValidationErrors({ success: "Successfully registered" });
          setTimeout(() => {
            navigate("/store", { state: { user: res } });
          }, 1000);
        } else {
          setValidationErrors({ err: res.message });
        }
      } catch (error) {
        console.error("Registration error:", error);
        setValidationErrors({
          err: "An error occurred during registration. Please try again later.",
        });
      }
      // Reset form fields
      setFormData({
        fname: "",
        lname: "",
        email: "",
        contact: "",
        store_name: "",
        password: "",
        reenterpassword: "",
      });
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.fname || formData.fname.trim() === "") {
      errors.fname = "Please enter your first name";
    }

    if (!formData.lname || formData.lname.trim() === "") {
      errors.lname = "Please enter your last name";
    }
    if (!formData.email || formData.email.trim() === "") {
      errors.email = "Please enter an email address";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.contact || formData.contact.trim() === "") {
      errors.contact = "Please enter a contact number";
    } else if (!validateContactNumber(formData.contact)) {
      errors.contact = "Please enter a valid contact number";
    }

    if (!formData.store_name || formData.store_name.trim() === "") {
      errors.store_name = "Please enter your store name";
    }

    if (!formData.password || formData.password.trim() === "") {
      errors.password = "Please enter a password";
    }

    if (!formData.reenterpassword || formData.reenterpassword.trim() === "") {
      errors.reenterpassword = "Please confirm your password";
    } else if (formData.password !== formData.reenterpassword) {
      errors.reenterpassword = "Passwords do not match";
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateContactNumber = (contactNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(contactNumber);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-4">
          <div className="ownerlogin">
            <div className="ownerloginheader">
              <div className="nbadyplogo">
                <img src={mainlogo} alt="Logo" />
                <h4>DYP Local Support</h4>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <h4>Register</h4>
              <div className="mb-3">
                <label htmlFor="fname" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  name="fname"
                  aria-describedby="fname"
                  value={formData.fname}
                  onChange={handleInputChange}
                />
                {validationErrors.fname && (
                  <div className="text-danger">{validationErrors.fname}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="lname" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lname"
                  name="lname"
                  aria-describedby="lname"
                  value={formData.lname}
                  onChange={handleInputChange}
                />
                {validationErrors.lname && (
                  <div className="text-danger">{validationErrors.lname}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="email"
                  value={formData.email || ""}
                  onChange={handleInputChange}
                />
                {validationErrors.email && (
                  <div className="text-danger">{validationErrors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="contact" className="form-label">
                  Contact
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                />
                {validationErrors.contact && (
                  <div className="text-danger">{validationErrors.contact}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="storename" className="form-label">
                  Store Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="store_name"
                  name="store_name"
                  value={formData.store_name}
                  onChange={handleInputChange}
                />
                {validationErrors.store_name && (
                  <div className="text-danger">
                    {validationErrors.store_name}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="col">
                  <label htmlFor="reenterpassword" className="form-label">
                    Re-enter Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="reenterpassword"
                    name="reenterpassword"
                    value={formData.reenterpassword}
                    onChange={handleInputChange}
                  />
                  {validationErrors.reenterpassword && (
                    <div className="text-danger">
                      {validationErrors.reenterpassword}
                    </div>
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            <div className="nbaloginfooter">
              <span>Already have an account? </span>
              <Link to="/store">Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
