import React from "react";
import checkedinimg from "../../img/checkedin.png";
import { useLocation } from "react-router";
import useFetch from "../../hooks/useFetch";

export default function Checkedin() {
  const { state } = useLocation();

  const { data, isLoading } = useFetch(
    `users/get-bill?check_in_id=${state.data.checkInDetail[0].checkin_id}`,
    5000
  );

  console.log(data);

  const flag = 0;
  let paybillContent = null;

  if (flag === 0) {
    paybillContent = (
      <div class="container-fluid">
        <div class="paybill">
          <div class="headerpay">
            <span>You have new bill to pay</span>
            <span>
              Amount <strong>$100</strong>
            </span>
          </div>
          <div class="payvia">
            <span>Pay Via DYP and Get 100 NBA Coins </span>
            <button>
              Pay Now <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>

          <button type="button" class="btn btn-link endtext">
            Pay at counter
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div class="container-fluid darkgreybg">
        <div class="usercheckedin">
          <img src={checkedinimg} />
          <span>You are checked in</span>
          <h4>Name of User</h4>
          <address>2 Columbus Cir, New York, NY 10019, United States</address>
        </div>
        <div></div>
      </div>
      {/* 
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#paybill"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="paybill"
        tabindex="-1"
        aria-labelledby="paybill"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="paybill">
                <div class="headerpay">
                  <span>You have new bill to pay</span>
                  <span>
                    Amount <strong>$100</strong>
                  </span>
                </div>
                <div class="payvia">
                  <span>Pay Via DYP and Get 100 NBA Coins </span>
                  <button>
                    Pay Now <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>

                <button type="button" class="btn btn-link endtext">
                  Pay at counter
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <div>{paybillContent}</div>
      {/* <div class="container-fluid">
        <div class="paybill">
          <div class="headerpay">
            <span>You have new bill to pay</span>
            <span>
              Amount <strong>$100</strong>
            </span>
          </div>
          <div class="payvia">
            <span>Pay Via DYP and Get 100 NBA Coins </span>
            <button>
              Pay Now <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>

          <button type="button" class="btn btn-link endtext">
            Pay at counter
          </button>
        </div>
      </div> */}

    </>
  );
}
