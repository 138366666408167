import React from 'react'
import './App.css';
import Login from './components/restaurantownerflow/Login';
import { Route, Routes } from 'react-router-dom';
import Register from './components/restaurantownerflow/Register';
import AllGuest from './components/restaurantownerflow/AllGuest';
import PaymentHistory from './components/restaurantownerflow/PaymentHistory';
import DefaultContainer from './components/restaurantownerflow/DefaultContainer';
import AuthRoute from './AuthRoute';
import UserLogin from './components/userflow/UserLogin';
import UserRegister from './components/userflow/UserRegister';
import Checkedin from './components/userflow/Checkedin';
import ThankYouMessage from './components/userflow/ThankYouMessage';
function UserApp() {
  return (
    <>
      <Routes>
        {/* <Route element={<AuthRoute />}> */}
        {/* <Route element={<DefaultContainer />}> */}
        <Route path="/checkedin" element={<Checkedin />} />
        <Route path="/thank-you" element={<ThankYouMessage />} />
        {/* <Route path="/paymenthistory" element={<PaymentHistory />} /> */}
        {/* </Route>
        </Route> */}
        <Route path="/" element={<UserLogin />} />
        <Route path="/register" element={<UserRegister />} />
      </Routes>
    </>
  );
}

export default UserApp;
