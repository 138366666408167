import React, { useEffect, useState } from "react";
import mainlogo from "../../img/logo-dyp.png";
import { useNavigate } from "react-router";
import axios from "axios";
import { API_URL } from "../../services/upload-files.service";
import { axiosInstance } from "../../services/fetch-data.service";

export default function UserLogin() {
  const [urlData, setUrlData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const store_id = urlParams.get("store_id");
    setUrlData({ store_id });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target
    // const { value: inputValue } = e.target;
    // Regular expression to match only positive decimal numbers
    // const regex = /^(?!0\d)\d*(\.\d+)?$/;

    // Check if the input value is either empty (allowing deletion) or matches the positive decimal regex
    // if (inputValue === '' || regex.test(inputValue)) {
    setUrlData((prevData) => ({ ...prevData, [name]: value }))
    // }
    // setUrlData((prevData) => ({ ...prevData, [name]: value }))
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(urlData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        if (Object.keys(urlData).length > 0) {
          const { data } = await axios.post(
            `${API_URL}/users/track-spend`,
            urlData
          );
          if (data.success) {
            setValidationErrors({ succes: "successfully loggedin" });
            setUrlData({}); // Clear form data here
            navigate("/thank-you");
          } else {
            setValidationErrors({ err: data.message });
          }
        }
      } catch (error) {
        setValidationErrors({
          err: "An error occurred. Please try again later.",
        });
      }
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.email || formData.email.trim() === "") {
      errors.email = "Please enter an email address";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.amount || formData.amount.trim() === "") {
      errors.amount = "Please enter amount";
    } else if (!validateNumber(formData.amount)) {
      errors.amount = "Please enter a valid  amount";
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateNumber = (amount) => {
    const re = /^(?!0\d)\d*(\.\d+)?$/;
    return re.test(amount);
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-4">
          <div className="ownerlogin">
            <div className="ownerloginheader">
              <div className="nbadyplogo">
                <img src={mainlogo} alt="Main Logo" />
                <h4>DYP Local Support</h4>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <h4>Enter your spend details</h4>
              {validationErrors.err && (
                <div className="text-danger">{validationErrors.err}</div>
              )}
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Enter your email id</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={urlData.email}
                  aria-describedby="email"
                  onChange={handleChange}
                />
                {validationErrors.email && (
                  <div className="text-danger">{validationErrors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="amount" className="form-label">Enter amount that you spent at this outlet ($)</label>
                <input
                  type="text"
                  className="form-control"
                  id="amount"
                  name="amount"
                  value={urlData.amount}
                  aria-describedby="amount"
                  onChange={handleChange}
                />
                {validationErrors.amount && (
                  <div className="text-danger">{validationErrors.amount}</div>
                )}
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
