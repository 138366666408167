import React, { useState } from "react";
import mainlogo from "../../img/logo-dyp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { create } from "../../services/upload-files.service";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormFields(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const res = await create(formData, "login");
        if (!res || !res.token) {
          setValidationErrors({ err: res.message });
        } else {
          setValidationErrors({
            success: "successfully loggedin",
          });
          localStorage.setItem("token", res.token);
          localStorage.setItem("storeId", res.user.id);
          localStorage.setItem("storename", res.user.store_name);
          const route = "/store/allguest";
          navigate(route, { state: { user: res } });
        }
      } catch (error) {
        setValidationErrors({
          err: "An error occurred during login. Please try again later.",
        });
      }
      setFormData({});
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.email || formData.email.trim() === "") {
      errors.email = "Please enter an email address";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.password || formData.password.trim() === "") {
      errors.password = "Please enter a password";
    }

    return errors;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-6 col-lg-4">
          <div className="ownerlogin">
            <div className="ownerloginheader">
              <div className="nbadyplogo">
                <img src={mainlogo} alt="Main Logo" />
                <h4>DYP Local Support</h4>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <h4>Login</h4>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleInputChange}
                />
                {validationErrors.email && (
                  <div className="text-danger">{validationErrors.email}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  value={formData.password || ""}
                  onChange={handleInputChange}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                />
                {validationErrors.password && (
                  <div className="text-danger">{validationErrors.password}</div>
                )}
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember me
                </label>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            {validationErrors.err && (
              <div className="text-danger loginerror">
                {validationErrors.err}
              </div>
            )}
            <div className="nbaloginfooter">
              <span>Do not have an account? </span>
              <Link to="/store/register">Register Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
