import React from 'react'
import useFetch from '../../hooks/useFetch';
import { formatDate } from './Functions';
import { jwtDecode } from 'jwt-decode';

export default function PaymentHistory() {

    const storeId = localStorage.getItem('storeId');

    const { data, isLoading } = useFetch(`stores/payment-history?store_id=${storeId && storeId}`);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Check if data exists and payment_total exists
    if (!data || !data.payment_total || data.payment_total.length === 0 || !data.payment_history || !data.payment_history.length === 0) {
        return <div>No payment history available.</div>;
    }

    return (
        <>

            <div class="container-fluid greybg">
                <div class="paymenthistory">
                    <div class="phcollection">
                        <span class="mainspan">Collections</span>
                        <div class="bigblock" >
                            {data.payment_total.map((payment, index) => {
                                return (
                                    <>
                                        <div key={index}>
                                            <strong>$ {payment.total}</strong>
                                            <span>{payment.payment_mode} Payments</span>
                                        </div>
                                    </>
                                )
                            })
                            }
                        </div>
                    </div>
                    <h4>Payment History </h4>
                    <div class="smallblocks">
                        {data.payment_history.map((pymtHistory, index) => {

                            return (
                                <>
                                    <div class="smallblock" key={index}>
                                        <div class="phheader">
                                            <span>TXN ID: {pymtHistory.id}</span><span>{formatDate(pymtHistory.created_on)}</span>

                                        </div>
                                        <h3>{pymtHistory.user_full_name}</h3>
                                        <hr />
                                        <div class="phfooter">
                                            <span>{pymtHistory.payment_mode} <strong>${pymtHistory.amount}</strong></span>
                                        </div>
                                    </div>
                                    {/* <div class="smallblock">
                                        <div class="phheader">
                                            <span>TXN ID: 1234</span><span>20 Jan 23</span>

                                        </div>
                                        <h3>Sam Stephens</h3>
                                        <hr />
                                        <div class="phfooter">
                                            <span>Online <strong>$35</strong></span>
                                        </div>
                                    </div>
                                    <div class="smallblock">
                                        <div class="phheader">
                                            <span>TXN ID: 1234</span><span>20 Jan 23</span>

                                        </div>
                                        <h3>Sam Stephens</h3>
                                        <hr />
                                        <div class="phfooter">
                                            <span>Online <strong>$32</strong></span>
                                        </div>
                                    </div> */}
                                </>
                            )
                        }

                        )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
